import * as React from "react";
// @ts-ignore
import * as style from '../index.module.scss';
// @ts-ignore
import * as mainStyle from '../../../theme/main.module.scss';
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {FC, useEffect, useState} from "react";
import {Client} from "../../../services/ApiService";
import {toastr} from "react-redux-toastr";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/pro-solid-svg-icons";
import {faCross, faTimes, faTrash} from "@fortawesome/pro-light-svg-icons";

type Props = {
    member: any,
    authKey: any
}

const ProductGroupRow: FC<Props> = ({member, authKey}: Props) => {
    const [showTextfield, setShowTextField] = useState(false);
    const [productGroups, setProductGroups] = useState([]);
    const [selectedProductGroup, setSelectedProductGroup] = useState(null);
    const [newProductGroup, setNewProductGroup] = useState('');
    const [createNewProductGroup, setCreateNewProductGroup] = useState(false);
    const [currentTitle, setCurrentTitle] = useState('');
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
       updateProductGroupList();
    }, [])

    useEffect(() => {
        if(productGroups.length > 0 && selectedProductGroup !== 0) {

            let title = productGroups.find((item) => {
                return item.ID == selectedProductGroup
            })?.Title;

            setCurrentTitle(title);
        }
    }, [productGroups, selectedProductGroup])

    function updateProductGroupList() {
        Client.Raw
            .get('ProductGroup?filter[VendorID]=' + member.VendorID)
            .then((groupRes) => {
                setProductGroups(groupRes.data);

                Client.Raw.get('Product/' + member.LastEditedProductID).then((res) => {
                    setSelectedProductGroup(res.data.ProductGroupID);
                })
            });
    }

    function submitProductGroup(value) {
        Client.Auth.authPut(authKey, 'Product', member.LastEditedProductID, {ProductGroupID: value}).then(() => {
            setSelectedProductGroup(value);
            setShowTextField(false);
            setCreateNewProductGroup(false)

            toast.success('Produktgruppe wurde aktualisiert!');
        })
    }

    function updateProductGroup() {
        Client.Auth.authPost(authKey, 'ProductGroup', selectedProductGroup, {Title: currentTitle}).then((res) => {
            setSelectedProductGroup(res.data.ID);
            setShowTextField(false);
            setCreateNewProductGroup(false)

            Client.Raw
                .get('ProductGroup?filter[VendorID]=' + member.VendorID)
                .then((res) => {
                    setProductGroups(res.data);

                    Client.Raw.get('Product/' + member.LastEditedProductID).then((res) => {
                        setSelectedProductGroup(res.data.ProductGroupID);
                        toast.success('Produktgruppe wurde aktualisiert!');

                    })
                });

        })
    }

    function submitNewProductGroup() {
        Client.Auth.authPostNoID(authKey, 'ProductGroup', {
            Title: currentTitle,
            VendorID: member.VendorID
        }).then((res) => {
            Client.Auth.authPut(authKey, 'Product', member.LastEditedProductID, {ProductGroupID: res.data.ID}).then(() => {

                updateProductGroupList();
                setShowTextField(false)
                setCreateNewProductGroup(false)
                toast.success(newProductGroup + ' wurde hinzugefügt!');
            })
        })
    }

    const deleteProductGroup = () => {
        Client.Auth.authDelete(authKey, 'ProductGroup', selectedProductGroup).then((res) => {
            updateProductGroupList();
            handleClose();
            toast.success('Produktgruppe wurde gelöscht!');
        })
    }

    const handleClose = () => {
        setShowModal(false);
    }

    return (
        <>

            <Modal className={'text-center'} size={'lg'} centered={true} show={showModal} onHide={handleClose} contentClassName={style.modal}>
                <Modal.Header>
                    <Modal.Title className={'text-center ' + style.modalTitle}>Produktgruppe löschen? <Button onClick={handleClose} style={{float: "right"}}>
                        <FontAwesomeIcon icon={faTimes}/>
                    </Button></Modal.Title>
                </Modal.Header>
                <Modal.Body className={'py-3'}>
                    <h4>Möchtest du die Produktgruppe wirklich löschen?</h4>
                    <p>Dabei haben alle Produkte, die dieser Produktgruppe zugeordnet sind, ab sofort auch keine Produktgruppe mehr!</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button  variant={'primary'}
                             className={style.submitButton} onClick={deleteProductGroup}>
                        Ja
                    </Button>
                    <Button variant={'primary'}
                            className={style.deleteButton + ' ms-3'} onClick={handleClose}>
                        Nein
                    </Button>
                </Modal.Footer>
            </Modal>

            {!showTextfield ? <Col lg={6}>
                    <Form.Group controlId="formProductGroup">
                        <Form.Label className={style.inputLabel}>Produktgruppe</Form.Label>
                        <Form.Control
                            as="select"
                            defaultValue={selectedProductGroup}
                            onChange={(event) => submitProductGroup(event.target.value)}
                            className={style.dropDown + ' form-select mt-2'}
                        >
                            <option value={0}>Keine</option>
                            {productGroups.length != 0 ? productGroups.map((pg, index) => {
                                return (
                                    <option value={pg.ID} key={index} selected={selectedProductGroup === pg.ID}>
                                        {pg.Title}
                                    </option>
                                )
                            }) : null}
                        </Form.Control>
                        <Form.Text className={mainStyle.errorMsg}>
                        </Form.Text>
                    </Form.Group>
                </Col> :
                <Col lg={6}>
                    <Form.Group controlId="formProductGroupTitle">
                        <Form.Label className={style.inputLabel}>{createNewProductGroup ? 'Namen eingeben' : 'Namen ändern'}</Form.Label>

                                    <Form.Control type="text" className={style.inputField + ' mt-2 w-100'} defaultValue={currentTitle ?? ''}
                                                  onChange={(e) => {setCurrentTitle(e.target.value)}} />


                        <Form.Text className={mainStyle.errorMsg}>

                        </Form.Text>
                    </Form.Group>
                </Col>
            }
            <Col lg={6} className={"text-end mb-3 mb-md-0"}>
                <div className={'mt-5 d-flex align-items-center justify-content-end'}>
                    <Button
                        type={"button"}
                        variant={'primary'}
                        onClick={!showTextfield ? () => {setCreateNewProductGroup(true); setShowTextField(true);} : (createNewProductGroup ? submitNewProductGroup : updateProductGroup)}
                        className={style.submitButton}
                    >
                        {!showTextfield ? 'Neue Gruppe' : (createNewProductGroup ? 'Erstellen' : 'Aktualisieren')}
                    </Button>
                    {!showTextfield && selectedProductGroup != 0 ?
                        <>
                            <FontAwesomeIcon icon={faPen} className={mainStyle.cursorPointer + ' ms-2'} onClick={() => setShowTextField(true)}/>
                            <FontAwesomeIcon icon={faTrash} className={mainStyle.cursorPointer +' ms-2'} onClick={() => setShowModal(true)}/>
                        </>
                        : <FontAwesomeIcon icon={faTimes} className={mainStyle.cursorPointer + ' ms-2'} onClick={() => {setShowTextField(false); setCreateNewProductGroup(false)}}/>}
                </div>
            </Col>

        </>
    )
}

export default ProductGroupRow;
